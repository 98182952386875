import { Entity, Column, PrimaryGeneratedColumn } from 'typeorm/browser';
@Entity()
export class Test {
  @PrimaryGeneratedColumn()
  id: number;
  @Column()
  name: string;
  @Column()
  description: string;
}
