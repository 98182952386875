import { DatabaseService } from './shared/database.service';
import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActionSheetController } from '@ionic/angular';
import firebase from 'firebase/compat/app';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Dashboard', url: '/dashboard', icon: 'apps' },
    { title: 'Health', url: '/health', icon: 'medkit' },
    { title: 'Finance', url: '/finance', icon: 'calculator' },
    { title: 'Workflows', url: '/workflows', icon: 'git-pull-request' },
    { title: 'Journal', url: '/journal', icon: 'happy' },
    { title: 'Timer', url: '/timer', icon: 'timer' },
    { title: 'Streaker', url: '/streaker', icon: 'flame' },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(
    public auth: AngularFireAuth,
    private actionSheetCtrl: ActionSheetController,
    public databaseService: DatabaseService) { }
  login() {
    this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }
  logout() {
    this.auth.signOut();
  }
  downloadDatabase() {
    this.databaseService.downloadDatabase();
  }
  async selectDatabase() {
    console.log('select database')
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Select Database',
      buttons: [
        {
          text: 'Create Database',
          icon: 'add-circle-outline',
          handler: () => {
            this.databaseService.createDatabase();
            console.log('Create Database clicked');
          }
        }, {
          text: 'Pick Database',
          icon: 'folder-open-outline',
          handler: () => {
            this.databaseService.pickDatabase();
            console.log('Pick Database clicked');
          }
        }, {
          text: 'Cancel',
          icon: 'close',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });
    await actionSheet.present();
  }
}
