import { async } from '@angular/core/testing';
import { Test } from './../../db/models/workflows';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SqlJsConfig, SqlJsStatic } from 'sql.js';
import * as SqlWasmJs from 'sql.js/dist/sql-wasm.js';
import { DataSource } from "typeorm/browser";

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  isDatabasePicked = false;

  $isDatabasePicked = new BehaviorSubject<boolean>(this.isDatabasePicked);
  Sql: SqlJsStatic;
  Database = null;
  fileHandler = null;
  typeOrmConnection: DataSource;
  constructor() {
  }
  async initSqlJs() {
    // (window as any).SQL = await initSqlJs({ locateFile: file => `https://sql.js.org/dist/${file}` });
  }
  async pickDatabase() {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.db';
    fileInput.addEventListener('change', async (event) => {
      const file = (event.target as HTMLInputElement).files[0];
      const fileContents = await file.arrayBuffer();
      const Uints = new Uint8Array(fileContents);
      await this.initSqlJs();
      this.initiateDatabase(Uints, false);
      this.$isDatabasePicked.next(true);
    });
    fileInput.click();
  }
  async createDatabase() {
    await this.initSqlJs();
    this.initiateDatabase(null, true);
    this.$isDatabasePicked.next(true);
  }

  async downloadDatabase() {
    console.log('downloading database');
    // const arraybuff = this.Database.export();
    const arraybuff = this.typeOrmConnection.sqljsManager.exportDatabase();
    const blob = new Blob([arraybuff]);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(blob);
    a.download = 'sql.db';
    a.onclick = () => {
      setTimeout(() => {
        window.URL.revokeObjectURL(a.href);
      }, 1500);
    };
    a.click();
    if (confirm("delete the in-memory database?")) {
      await this.typeOrmConnection.dropDatabase();
    }
  }

  initiateDatabase(uInts: Uint8Array, isNewlyCreated: boolean = false) {
    (window as any).SQL = SqlWasmJs;
    const sqlJsConfig: SqlJsConfig = {};
    const AppDataSource = new DataSource({
      name: 'default',
      type: 'sqljs',
      database: uInts,
      autoSaveCallback: this.saveCallback,
      autoSave: true,
      entities: [Test],
      // logging: ['error', 'query', 'schema'],
      synchronize: isNewlyCreated,
      sqlJsConfig: {
        locateFile: (file: any) => `/${file}`
      },
    });
    AppDataSource.initialize()
      .then((connection) => {
        // here you can start to work with your database
        this.typeOrmConnection = connection;
      })
      .catch((error) => console.log(error));
  }
  async writeDummyData() {
    let x = 0;
    const ref = setInterval(async () => {
      for (let i = 0; i < 10_000; i++) {
        const testRepo = this.typeOrmConnection.getRepository(Test);
        const test = new Test();
        const randomNumber = Math.floor(Math.random() * 1000);
        test.name = 'test ' + randomNumber;
        test.description = 'test description ' + randomNumber;
        await testRepo.save(test);
        if (x > 100) {
          clearInterval(ref);
        }
      }
      x += 1;
      console.log('loop completed', x);
    }, 10000);

  }

  saveCallback() {
    // console.log('save callback called');
  }

  async readData() {
    const testRepo = this.typeOrmConnection.getRepository(Test);
    const tests = await testRepo.find();
    return tests;
  }
}
